<template>
  <div class="overlap">
    <div class="overlap-group-2">
      <div class="overlap-group-3">
        <img class="book" alt="Book" src="../../../../static/img/book-glasses3-1.png" />
        <img class="line" alt="Line" src="../../../../static/img/line1-1.png" />
        <img class="cofffee" alt="Cofffee" src="../../../../static/img/cofffee6-1.png" />
        <div class="text-wrapper">Personal Projects</div>
        <div class="text-wrapper-2">Education</div>
        <p class="managed-a-vietnamese">
          Managed a Vietnamese student social media(Facebook) outreach to keep students updated on local news; course
          work; and international admission information. <br />
          Q&amp;A responder for prospective Vietnamese students.
        </p>
        <img class="img" alt="Line" src="../../../../static/img/line2-1.png" />
      </div>
      <div class="text-wrapper-3">Employment</div>
      <div class="r">Software Engineer, Intern</div>
      <div class="r-2">FPT USA Corp</div>
      <p class="p">Jun 2021 - Dec 2021</p>
      <div class="r-3">Cape Girardeau, MO</div>
      <div class="r-4">Southeast Missouri State University</div>
      <p class="automate-internal">
        Automate internal team logins with Selenium.&nbsp;&nbsp;
        <br />
        Built a proof-of-concept library management system. <br />
        Worked with version control to track continuous updates to internal applications
      </p>
      <p class="r-5">Fall 2017 – May 2021</p>
      <p class="b-s-in-computer">
        B.S. in Computer Science with Minor in Mathematics, May 2021. <br />
        GPA: 3.94 Summa Cum Laude; in-major GPA: 4.0 for Computer Science.&nbsp;&nbsp;
        <br />
        Undergraduate Coursework:
      </p>
      <div class="text-wrapper-4">Operating Systems</div>
      <div class="text-wrapper-5">Software Engineering</div>
      <div class="calculus-i-II">Calculus I &amp; II</div>
      <div class="r-6">Microsoft Cloud Application, Developer</div>
      <div class="text-wrapper-6">Databases</div>
      <div class="text-wrapper-7">Machine Learning</div>
      <div class="text-wrapper-8">Linear Algebra</div>
      <div class="text-wrapper-9">Programming Languages</div>
      <div class="text-wrapper-10">Data Analysis</div>
      <div class="text-wrapper-11">Mathematical Cryptography</div>
      <div class="r-7">Social Media Ambassador</div>
      <div class="r-8">Itlize Global, LLC</div>
      <div class="r-9">Southeast Missouri State University</div>
      <div class="r-10">Nov 2021 - Current</div>
      <p class="r-11">Fall 2019 – Spring 2021</p>
      <p class="develop-webpages">
        Develop webpages using .NET and Angular according to customers’ requirements. <br />
        Update old code base and legacy code bases to current versions of framework (ASP.NET 5 to 6; Angular 5 to
        Angular 13, etc.). <br />
        Documentation of applications and databases used in production. <br />
        Worked with Microsoft Azure to implement Ci/CD pipeline into the software development lifecycle. Increased up
        to 20% of developer team productivity and efficiency.
      </p>
      <img class="keycaps" alt="Keycaps" src="../../../../static/img/keycaps3-1.png" />
    </div>
    <div class="r-12">Personal e-commerce website</div>
    <p class="r-13">Smeexer - Cryptocurrency private-seed mixers</p>
    <div class="r-14">2021 - 2022</div>
    <div class="r-15">2021</div>
    <p class="text-wrapper-12">
      An online e-commerce site specializing in mechanical keyboard related products such as keycaps, PCB boards, and
      mechanical switches where multiple users can view, sign up, add products to cart, and even pay using Stripe
      payment API.
    </p>
    <p class="text-wrapper-13">
      Console application, allowing users to methodically scramble private seed phrases of cryptocurrency wallets to
      safely store them offline and online.
    </p>
    <p class="c-ASP-NET-web-API">
      C# <br />
      ASP.NET 6 Web API
      <br />
      Angular 13
    </p>
    <div class="text-wrapper-14">Python</div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  name: "Overlap",
});
</script>

<style>
.overlap {
  height: 2077px;
  left: 127px;
  position: absolute;
  top: 1161px;
  width: 1700px;
}

.overlap .overlap-group-2 {
  height: 2055px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1700px;
}

.overlap .overlap-group-3 {
  height: 1702px;
  left: 25px;
  position: absolute;
  top: 0;
  width: 1642px;
}

.overlap .book {
  height: 541px;
  left: 1010px;
  position: absolute;
  top: 942px;
  width: 624px;
}

.overlap .line {
  height: 414px;
  left: 224px;
  object-fit: cover;
  position: absolute;
  top: 697px;
  width: 804px;
}

.overlap .cofffee {
  height: 704px;
  left: 0;
  position: absolute;
  top: 0;
  width: 430px;
}

.overlap .text-wrapper {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 90px;
  font-weight: 700;
  left: 648px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1585px;
}

.overlap .text-wrapper-2 {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 90px;
  font-weight: 700;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 975px;
}

.overlap .managed-a-vietnamese {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 617px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 703px;
  width: 1025px;
}

.overlap .img {
  height: 216px;
  left: 345px;
  object-fit: cover;
  position: absolute;
  top: 1397px;
  width: 833px;
}

.overlap .text-wrapper-3 {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 90px;
  font-weight: 700;
  left: 673px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 43px;
}

.overlap .r {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 672px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 221px;
}

.overlap .r-2 {
  color: #000000;
  font-family: "Articulat CF-DemiBold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 993px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 221px;
}

.overlap .p {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1519px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 221px;
}

.overlap .r-3 {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1140px;
}

.overlap .r-4 {
  color: #000000;
  font-family: "Articulat CF-DemiBold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 278px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1140px;
}

.overlap .automate-internal {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 642px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 258px;
}

.overlap .r-5 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 712px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 1140px;
}

.overlap .b-s-in-computer {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1176px;
}

.overlap .text-wrapper-4 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 30px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1271px;
  white-space: nowrap;
}

.overlap .text-wrapper-5 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 30px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1306px;
  white-space: nowrap;
}

.overlap .calculus-i-II {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 30px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1341px;
  white-space: nowrap;
}

.overlap .r-6 {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 672px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 398px;
}

.overlap .text-wrapper-6 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 293px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1271px;
  white-space: nowrap;
}

.overlap .text-wrapper-7 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 293px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1306px;
  white-space: nowrap;
}

.overlap .text-wrapper-8 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 293px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1341px;
  white-space: nowrap;
}

.overlap .text-wrapper-9 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 496px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1271px;
  white-space: nowrap;
}

.overlap .text-wrapper-10 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 496px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1306px;
  white-space: nowrap;
}

.overlap .text-wrapper-11 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 496px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1341px;
  white-space: nowrap;
}

.overlap .r-7 {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 672px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 666px;
}

.overlap .r-8 {
  color: #000000;
  font-family: "Articulat CF-DemiBold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1120px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 398px;
}

.overlap .r-9 {
  color: #000000;
  font-family: "Articulat CF-DemiBold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 994px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 666px;
}

.overlap .r-10 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1532px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 398px;
}

.overlap .r-11 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1496px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 666px;
}

.overlap .develop-webpages {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 642px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 435px;
  width: 1025px;
}

.overlap .keycaps {
  height: 438px;
  left: 86px;
  object-fit: cover;
  position: absolute;
  top: 1617px;
  width: 448px;
}

.overlap .r-12 {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 672px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1762px;
}

.overlap .r-13 {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 672px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1975px;
}

.overlap .r-14 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1595px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 1762px;
}

.overlap .r-15 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1658px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 1975px;
}

.overlap .text-wrapper-12 {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 673px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1804px;
  width: 640px;
}

.overlap .text-wrapper-13 {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 673px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 2017px;
  width: 640px;
}

.overlap .c-ASP-NET-web-API {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1390px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: right;
  top: 1804px;
  width: 310px;
}

.overlap .text-wrapper-14 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 1390px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: right;
  top: 2017px;
  width: 310px;
}
</style>
