<template>
  <div class="overlap-wrapper">
    <div class="r-17">Employment</div>
    <img class="vector" alt="Vector" src="../../../../static/img/vector-17.svg" />
    <p class="hello-this-is-tin">
      <span class="span">Hello, this is </span>
      <span class="text-wrapper-21">Tin</span>
      <span class="span">. </span>
    </p>
    <p class="r-18">
      <span class="text-wrapper-22">I’m a </span>
      <span class="text-wrapper-23">caffiend</span>
      <span class="text-wrapper-22">, and a technologist.</span>
    </p>
    <div class="r-19">Education</div>
    <div class="r-20">Projects</div>
    <div class="r-21">Awards</div>
    <img class="tin" alt="Tin" src="../../../../static/img/tin-8-1.png" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OverlapWrapper",
});
</script>

<style>
.overlap-wrapper {
  height: 784px;
  left: 99px;
  position: absolute;
  top: 93px;
  width: 1733px;
}

.overlap-wrapper .r-17 {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 268px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 80px;
}

.overlap-wrapper .vector {
  height: 7px;
  left: 265px;
  position: absolute;
  top: 101px;
  width: 109px;
}

.overlap-wrapper .hello-this-is-tin {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 71.8px;
  font-weight: 400;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 264px;
}

.overlap-wrapper .span {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 71.8px;
  font-weight: 400;
  letter-spacing: 0;
}

.overlap-wrapper .text-wrapper-21 {
  font-weight: 700;
}

.overlap-wrapper .r-18 {
  color: #000000;
  font-family: "Articulat CF-Medium", Helvetica;
  font-size: 31.4px;
  font-weight: 400;
  left: 266px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 346px;
}

.overlap-wrapper .text-wrapper-22 {
  font-weight: 500;
}

.overlap-wrapper .text-wrapper-23 {
  font-family: "EB Garamond", Helvetica;
  font-style: italic;
  font-weight: 600;
}

.overlap-wrapper .r-19 {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 426px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 80px;
}

.overlap-wrapper .r-20 {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 562px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 80px;
}

.overlap-wrapper .r-21 {
  color: #000000;
  font-family: "EB Garamond", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 680px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 80px;
}

.overlap-wrapper .tin {
  height: 784px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1733px;
}
</style>
