<template>
  <div class="overlap-group-wrapper">
    <div class="text-wrapper-24">Python (panda and numpy)</div>
    <div class="text-wrapper-25">Machine Learning</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OverlapGroupWrapper",
});
</script>

<style>
.overlap-group-wrapper {
  height: 30px;
  left: 1156px;
  position: absolute;
  top: 3599px;
  width: 477px;
}

.overlap-group-wrapper .text-wrapper-24 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 287px;
}

.overlap-group-wrapper .text-wrapper-25 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 284px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 193px;
}
</style>