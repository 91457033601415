<template>
  <div class="overlap-group">
    <div class="overlap-2">
      <img class="line-2" alt="Line" src="../../../../static/img/line3-1.png" />
      <p class="r-16">Dean’s List at Southeast Missouri State University</p>
      <p class="maintained-a">
        Maintained a consistent 3.5 GPA for <br />8 consecutive semesters. <br />
        Fall 2017- Spring 2021
      </p>
      <div class="text-wrapper-15">PyCharm</div>
      <div class="text-wrapper-16">Git Version Control</div>
      <div class="text-wrapper-17">Docker</div>
      <div class="text-wrapper-18">Postman</div>
      <img class="mouse" alt="Mouse" src="../../../../static/img/mouse-2-1.png" />
    </div>
    <div class="text-wrapper-19">Kubernetes</div>
    <div class="text-wrapper-20">Jupyter Notebook</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OverlapGroup",
});
</script>

<style>
.overlap-group {
  height: 944px;
  left: 210px;
  position: absolute;
  top: 3798px;
  width: 1616px;
}

.overlap-group .overlap-2 {
  height: 944px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1574px;
}

.overlap-group .line-2 {
  height: 589px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 27px;
  width: 1166px;
}

.overlap-group .r-16 {
  color: #000000;
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 238px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
  width: 290px;
}

.overlap-group .maintained-a {
  color: #000000;
  font-family: "Articulat CF-Normal", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 238px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 75px;
  width: 338px;
}

.overlap-group .text-wrapper-15 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 946px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 2px;
  width: 287px;
}

.overlap-group .text-wrapper-16 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 946px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 35px;
  width: 287px;
}

.overlap-group .text-wrapper-17 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 1230px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 193px;
}

.overlap-group .text-wrapper-18 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 1230px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 32px;
  width: 193px;
}

.overlap-group .mouse {
  height: 564px;
  left: 1010px;
  object-fit: cover;
  position: absolute;
  top: 380px;
  width: 564px;
}

.overlap-group .text-wrapper-19 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 1441px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 175px;
}

.overlap-group .text-wrapper-20 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 1441px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 32px;
  width: 175px;
}
</style>
