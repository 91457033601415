<template>
  <div class="div">
    <div class="text-wrapper-28">ASP.NET Core</div>
    <div class="text-wrapper-29">.NET 5 and 6</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Div",
});
</script>

<style>
.div {
  height: 30px;
  left: 1156px;
  position: absolute;
  top: 3629px;
  width: 477px;
}

.div .text-wrapper-28 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 287px;
}

.div .text-wrapper-29 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 284px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 193px;
}
</style>