<template>
  <div class="section-component-node">
    <div class="text-wrapper-30">MySQL</div>
    <p class="text-wrapper-31">IntelliJ IDE and Android Studio</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SectionComponentNode",
});
</script>

<style>
.section-component-node {
  height: 30px;
  left: 1156px;
  position: absolute;
  top: 3768px;
  width: 566px;
}

.section-component-node .text-wrapper-30 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 287px;
}

.section-component-node .text-wrapper-31 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 284px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 282px;
}
</style>