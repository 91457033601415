<template>
  <div class="namecandidate-name-overlap-score-1000-1">
    <div class="text-wrapper-32">JavaScript</div>
    <div class="text-wrapper-33">Typescript</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NamecandidateNameOverlapScore10001",
});
</script>

<style>
.namecandidate-name-overlap-score-1000-1 {
  height: 30px;
  left: 1156px;
  position: absolute;
  top: 3659px;
  width: 477px;
}

.namecandidate-name-overlap-score-1000-1 .text-wrapper-32 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 287px;
}

.namecandidate-name-overlap-score-1000-1 .text-wrapper-33 {
  color: #000000;
  font-family: "Articulat CF-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 284px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 193px;
}
</style>